


























































































import { Component, Vue } from 'vue-property-decorator'
import { AgGridVue } from 'ag-grid-vue'
import ResolveCompanyId from '@/mixins/ResolveCompanyId'
import PartnerEducationProgramsAPI from '@/components/routes/partner/education-programs/helpers/requests'
import PartnerEducationSubjectsAPI from '@/components/routes/partner/education-subjects/helpers/requests'
import PartnersAPI from '@/api/partners'
import { userMappers } from '@/store/modules/user'

const Mappers = Vue.extend({
  computed: {
    ...userMappers.mapGetters(['isPartner', 'isPRManager', 'userCompany'])
  }
})

@Component({ components: { AgGridVue }, mixins: [ResolveCompanyId] })
export default class PartnerInterests extends Mappers {
  getCompanyId!: Function

  private companyId!: number
  private canEditPartnerPage = false

  private data: any = {}

  private backClick() {
    this.$router.go(-1)
  }

  private async created() {
    this.companyId = this.getCompanyId()

    const [
      responseSpec,
      responseSubject,
      responseInterest
    ] = await Promise.all([
      PartnerEducationProgramsAPI.getSpecialtiesPartner(this.companyId),
      PartnerEducationSubjectsAPI.getSubjectsPartner(this.companyId),
      PartnersAPI.getInterestsPartner(this.companyId)
    ])

    this.data = {
      specialties: responseSpec.data,
      subjects: responseSubject.data,
      interests: responseInterest.data
    }

    this.canEditPartnerPage =
      (this.isPartner && this.userCompany.company.id === this.companyId) ||
      this.isPRManager
  }
}
