




import { Component, Vue } from 'vue-property-decorator'
import PartnerInterests from '@/components/routes/partner/partner-interests/index.vue'

@Component({ components: { PartnerInterests } })
export default class PartnerInterestsRoute extends Vue {}
